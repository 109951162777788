import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import kingmetacoin from '../../assets/kingmeta-coin.gif';

function Loader() {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                alignItems: 'center',
                width: '275px',
            }}
        >
            <img src={kingmetacoin} alt="loading" style={{ width: '30%', maxWidth: '200px' }} />
            <TypeAnimation
                sequence={[
                    1000,
                    'Loading...', // Types 'One'
                    1000, // Waits 1s
                    'Loading.', // Deletes 'One' and types 'Two'
                    1000, // Waits 2s
                    'Loading..', // Types 'Three' without deleting 'Two'
                    1000, // Waits 2s
                    'Loading...', // Types 'Three' without deleting 'Two'
                ]}
                wrapper="span"
                cursor={true}
                repeat={Infinity}
                style={{ fontSize: '1.5rem', fontWeight: 'bold', marginTop: '2rem', color: 'aliceblue' }}
            />
        </div>
    );
}

export default Loader;
